import { configureStore } from "@reduxjs/toolkit";

// We'll use redux-logger just as an example of adding another middleware
import logger from "redux-logger";
import { studentInfoReducer } from "./Reducers/studentInfo";
import componentSlice from "./Reducers/componentSlice";
import fcmSlice from "./Reducers/fcmSlice";
import notificationSlice from "./Reducers/notificationSlice";

//Import Reducers

const reducer = {
  studentInfoReducer,
  componentData: componentSlice,
  fcmDetails: fcmSlice,
  notificationsList: notificationSlice,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [],
});

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together
