import React from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";

toast.configure();

const useStyles = makeStyles({
  bannerButton:{
    padding: "6px 12px",
    justifyContent: "center",
    alignItems: "center",
    width: "max-content",
    borderRadius: "4px",
    border:"1px solid #FF712D",
    background: "#FFF"
  },
});

const RequestMentorPopupButton = ({
  autoRescheduleGTM,
  setShowAlternateMentorModal
}) => {
  const classes = useStyles();

  const alternateMentorModal = (type) => {
    if (type === "open") {
      setShowAlternateMentorModal(true);
    } else {
      setShowAlternateMentorModal(false);
    }
  };
  return (
    <button className={classes.bannerButton}
    onClick={() => {
      autoRescheduleGTM("reschedule_alternate");
      alternateMentorModal("open");
    }}
>
<span style={{ 
 color: "#FF712D",
 fontFamily: "Poppins",
 "fontSize": "12px",
 }} >Request Substitute Teacher {""}
 <span><ArrowRightAltOutlinedIcon fontSize="medium" /></span>
 </span>
</button>
  );
};

export default RequestMentorPopupButton;
