import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Form, Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import LOGO from "../../assets/images/CY_logo_full.svg";
import rescheduleSidebarIcon from "../../assets/images/rescheduleSideBarIcon.svg";
import time from "../../assets/images/time.png";
import mentor from "../../assets/images/mentor.png";
import moment from "moment-timezone";
import Axios from "axios";
import { timeZoneList } from "../../utils/RescheduleTimeZones";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./Reschedule.scss";
import SuccessView from "./SuccessView";
import ErrorModal from "./ErrorModal";
import TagManager from "react-gtm-module";
import { decrypt } from "../../utils/other";
import { countFreeSlots, getSlotsCountByDay } from "./helper";
import { API } from "../../services/api-services";
import {useLocation} from "react-router-dom"
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "center",
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    height: "100vh",
    background: "#FFFFFF",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "center",
      height: "10vh",
    },
  },
  sideBarIconBoxsideBarIconBox: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  cyLogo: {
    margin: "32px auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
    maxWidth: '250px'
  },
  sidebarIcon: {
    heigth:"100%",
    width: "100%",
  },
  selectTimeBox: {
    background: "#FFFBFA",
    padding: "40px 0px 0px 40px",
    height: "100vh",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: "40px",
    },
  },
  header: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "28px",
    lineHeight: "42px",
    color: "#444444",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "27px",
      textAlign: "center",
    },
  },
  headerSubtext: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#555555",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  mentorAndTopicContainer: {
    width: "100%",
    minHeight: "94px",
    background: "#FFFFFF",
    boxShadow: "0px 1px 8px rgba(129, 11, 11, 0.08)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "20px 0px 0px 30px",
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 0px 10px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px 10px 30px",
      marginTop: "10px",
    },
  },
  mentorTopicName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#3D3D3D",
    marginTop: "10px",
  },
  mentorTopicHeading: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "24px",
    color: "#666666",
  },
  selectTimeContainer: {
    fontFamily: "Poppins",
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 1px 8px rgba(129, 11, 11, 0.08)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "20px 0px 20px 30px",
    marginTop: "20px",
    overflowX: "auto",
    "@media (max-width: 600px)": {
      width: "460px",
      padding: "20px 0px 10px 0px",
      marginTop: "30px",
    },
    "@media (max-width: 460px)": {
      width: "340px",
    },
    "@media (max-width: 400px)": {
      width: "310px",
    },
    "@media (max-width: 360px)": {
      width: "290px",
    },
    "@media (max-width: 320px)": {
      width: "240px",
    },
  },
  dayBox: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  activeBtn: {
    height: "80px",
    width: "64px",
    background: "#FF8547",
    border: "1px solid #FF8547",
    borderRadius: "4px",
    margin: "5px 0px 0px 20px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      height: "80px",
      width: "48px",
    },
  },
  disabledBtn: {
    height: "80px",
    width: "64px",
    background: "#D8C8C0",
    border: "1px solid #D8C8C0",
    borderRadius: "4px",
    margin: "5px 0px 0px 20px",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      height: "80px",
      width: "48px",
    },
  },
  activeTimeBtn: {
    height: "35px",
    width: "max-content",
    minWidth: "100px",
    padding: "10px 4px 0px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    background: "#FF8547",
    border: "1px solid #FF8547",
    borderRadius: "4px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      minWidth: "70px",
    },
  },
  inactiveBtn: {
    height: "80px",
    width: "64px",
    background: "#FFFFFF",
    border: "1px solid #BDBDBD",
    borderRadius: "4px",
    margin: "5px 0px 0px 20px",
    color: "#666666",
    "&:hover": {
      backgroundColor: "#FF8547",
      color: "white",
      border: "1px solid #FF8547",
    },
    [theme.breakpoints.down("xs")]: {
      height: "80px",
      width: "48px",
    },
  },
  inActiveTimeBtn: {
    height: "35px",
    width: "max-content",
    minWidth: "100px",
    padding: "10px 4px 0px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    background: "#FFFFFF",
    border: "1px solid #BDBDBD",
    borderRadius: "4px",
    color: "#666666",
    "&:hover": {
      backgroundColor: "#FF8547",
      color: "white",
      border: "1px solid #FF8547",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "70px",
    },
  },
  disabledTimeBtn: {
    height: "35px",
    width: "max-content",
    minWidth: "100px",
    padding: "10px 4px 0px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",
    background: "#D8C8C0",
    border: "1px solid #D8C8C0",
    borderRadius: "4px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      minWidth: "70px",
    },
  },
  day: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "10px",
    lineHeight: "14px",
    textTransform: "uppercase",
    marginTop: "4px",
  },
  date: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    marginTop: "4px",
  },
  displaySlots: {
    display: "grid",
    gridTemplateColumns: "repeat(6, 1fr)",
    gap: "5px",
    width: "100%",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  time: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  slotsNumber: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    width: "max-content",
    fontSize: "12px",
    lineHeight: "14px",
    margin: "5px 0px 0px 30px",
    color: "#2AC02A",
  },
  noSlots: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    width: "max-content",
    fontSize: "12px",
    lineHeight: "14px",
    margin: "5px 0px 0px 30px",
    color: "#F70404",
  },
  languageSelector: {
    position: "relative",
  },
  selectLanguageField: {
    "& .MuiSelect-selectMenu": {
      maxHeight: "360px",
      overflowY: "auto",
      fontFamily: "Poppins",
      fontSize: "14px",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "260px",
      },
    },
  },
  submitBtn: {
    padding: "8px 30px",
    color: "white",
    width: "335px",
    height: "43px",
    fontSize: "18px",
    background: "#FF712D",
    boxShadow: "0px 1px 2px #FBE2C6",
    borderRadius: "6px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FF712D",
      color: "white",
    },
    "@media (max-width: 390px)": {
      width: "320px",
    },
    "@media (max-width: 320px)": {
      width: "280px",
    },
  },
  disableSubmitBtn: {
    padding: "8px 30px",
    width: "335px",
    height: "43px",
    fontSize: "18px",
    borderRadius: "6px",
    textTransform: "none",
    background: "#D8C8C0",
    border: "1px solid #D8C8C0",
    color: "#FFFFFF",
    "&:hover": {
      background: "#D8C8C0",
      color: "#FFFFFF",
    },
    "@media (max-width: 390px)": {
      width: "320px",
    },
    "@media (max-width: 320px)": {
      width: "280px",
    },
  },
  submitBtnwrapper: {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 425px)": {
      position: "fixed",
      bottom: 0,
      border: "1px solid white",
      background: "#FFFFFF",
      padding: "10px 20px",
      width: "425px",
    },
    "@media (max-width: 375px)": {
      position: "fixed",
      bottom: 0,
      border: "1px solid white",
      background: "#FFFFFF",
      padding: "10px 20px",
      width: "375px",
    },
  },
  sbmitBtnBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "40px",
    paddingBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
    },
  },
}));

const Reschedule = () => {
  const classes = useStyles();
  const theme = useTheme();

  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const userObj = localStorage.getItem("user");
  const user = decrypt(userObj);
  const [classDetails, setClassDetails] = useState();
  const [mentorId, setMentorId] = useState();
  const [slots, setSlots] = useState([]);
  const [allSlots, setAllSlots] = useState({});
  const [selectedButton, setSelectedButton] = useState(0);
  const [nextSevenDays, setNextSevenDays] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timings, setTimings] = useState(null);
  const [timeZone, setTimeZone] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [displayedSlots, setDisplayedSlots] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [scheduleId,setScheduleId] = useState();
  const [selectedSlot, setSelectedSlot] = useState({
    slotId: "",
    slotButtonState: "",
  });
  const [isFreeSlotAvailable,setFreeSlotAvailable] = useState()
  const [lastClassPassed,setLastClassPassed] = useState()
  const formateDate = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const formatedTime = `${hours}:${minutes} ${ampm}`;
    return formatedTime;
  };
  const timeZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: timeZ })
  );
  const autoRescheduleGTM = (event_action) => {
    const tagManagerArgs = {
      gtmId: "GTM-TH4RWGG",
      dataLayer: {
        event: "codeyoung_portal",
        event_category: "codeyoung_portal",
        event_action,
        event_label: user.studentEmail || "",
        event_label1: mentorId,
        event_label2: scheduleId,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
    TagManager.initialize(tagManagerArgs);
  };

  const getQueryParams = (query)=>{
    const param = new URLSearchParams(location.search);
    console.log(location.search)
    return param.get(query);
  }
  useEffect(() => {
    setLoader(true);
    const courseId = getQueryParams("courseId")
    API.get(
      `${process.env.REACT_APP_STUDENTLOGIN_BASE_URL}class/add?courseId=${courseId}`
    )
      .then((res) => {
        if (res?.data.response) {
          if (res.data.response.showSuccess) {
            setShowSuccess(true);
          } else {
            const { classInStudentTz } = res.data.response;
            const classTime = moment(classInStudentTz).format("HH:mm");
            setFormattedTime(classTime);
            setTimeZone(res.data?.response.timeZone || "");
            setClassDetails(res.data.response);
            setMentorId(res.data?.response.classDetails?.MID);
            setScheduleId(res.data?.response.scheduleId)
            setLastClassPassed(res.data?.response.lastClassPassed)
          }
          setLoader(false);
        }
      })
      .catch((e) => {
        console.error("Error While Fetching data", e);
        setLoader(false);
      });
  }, []);
  useEffect(() => {
    if (classDetails) {
      const time = moment(classDetails.classDetails.indiaStartTime)
        .subtract(330, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      const a = moment.utc(time.replace(/ /g, "T")).tz(timeZone);
      setTimings(a);
    }
  }, [classDetails, timeZone]);
  useEffect(() => {
    setLoader(true);
    setDisplayedSlots([]);
    setSelectedButton(0);
    getSlots(startDate);
  }, [timeZone, mentorId, scheduleId]);

  useEffect(() => {
    if (mentorId) {
      autoRescheduleGTM("reschedule_opened");
    }
  }, [mentorId]);

  const getSlots = (date) => {
    const selectedDate = moment(date).format("YYYY-MM-DD");
    const convertedDate = new Date(selectedDate).toISOString();
    if (mentorId && scheduleId ) {
      API.get(
        `${process.env.REACT_APP_STUDENTLOGIN_BASE_URL}class/add/${mentorId}?timeZone=${timeZone}&scheduleId=${scheduleId}`
      )
        .then((res) => {
          setSelectedSlot({
            slotId: "",
            slotButtonState: "",
          });
          setNextSevenDays(res.data?.next7days);
          setFreeSlotAvailable(res.data?.anyFreeSlot)
          if (countFreeSlots(res.data?.slotUserTimzoneWise) === 0) {
            autoRescheduleGTM("reschedule_no_slots");
          }
          setAllSlots(res.data.slotUserTimzoneWise);
          setSlots(
            res.data.slotUserTimzoneWise[
              moment(convertedDate).format("DD MMM YYYY (ddd)")
            ] ?? []
          );
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.error("Error While Fetching data", e);
        });
    }
  };
  useEffect(() => {
    dayBasedTimeSlot();
  }, [slots]);
  const selectDate = (index, date) => {
    setLoader(true);
    setSelectedSlot({
      slotId: "",
      slotButtonState: "",
    });
    setSelectedButton(index);
    setSlots(allSlots[date] ?? []);
    setLoader(false);
  };
  const dayBasedTimeSlot = () => {
    setDisplayedSlots([]);
    if (selectedButton == "0") {
      const getSlotsCountByDay = (day) => {
        if (allSlots.hasOwnProperty(day)) {
          return allSlots[day].length;
        }
        return 0;
      };
      if (getSlotsCountByDay(nextSevenDays[0]) != "0") {
        selectDate(0, nextSevenDays[0]);
      }
    }
    let slotss = slots;
    if (slotss.length > 0) {
      setDisplayedSlots(slotss);
    }
  };

  const handleSelectSlot = (id, key) => {
    setSelectedSlot({
      slotId: id,
      slotButtonState: key,
    });
  };
  const onTimeZoneChangeHandler = (e) => {
    setTimeZone(e.target.value);
  };
  const submitHandler = () => {
    if (selectedSlot.slotId === "")
      return toast.error("Please Enter a slot time", {
        position: toast.POSITION.TOP_RIGHT,
      });
    setSubmitLoader(true);


    API.post(
      `${process.env.REACT_APP_STUDENTLOGIN_BASE_URL}class/add`,
      {
        slotId: selectedSlot.slotId,
        requesterType: "student",
        batchId:classDetails.classDetails.batchId,
        mentorId:classDetails.classDetails.MID, 
      }
    )
      .then((res) => {
        setSubmitLoader(false);
        if (res?.data?.response?.success) {
          setShowSuccess(true);
          autoRescheduleGTM("reshedule_submitted");
        } else {
          setShowError(true);
        }
      })
      .catch((e) => {
        setSubmitLoader(false);
        toast.error(e.response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      {showSuccess ? (
        <SuccessView />
      ) : showError ? (
        <SuccessView error={showError} setError={setShowError} />
      ) : (
        <div className={classes.root}>
          <Box sx={{ flexGrow: 1 }} className={classes.parent}>
            <Grid container>
              {!isSmallScreen && (
                <Grid item xs={12} sm={4}>
                <div className={classes.logoBox}>
                  <div className={classes.sideBarIconBox}>
                    <img
                      src={"https://cy-assets.s3.ap-south-1.amazonaws.com/student-portal/icons/side--illustration--graphic.png"}
                      alt="calenderIcon"
                      className={classes.sidebarIcon}
                    />
                  </div>
                  <img src={LOGO} alt="cy_logo" className={classes.cyLogo} />
                </div>
              </Grid>
              )
              }
              <Grid item xs={12} sm={8}>
                <Box className={classes.selectTimeBox}>
                  <Box className={classes.header}>Select preferred timing</Box>
                  <Box className={classes.headerSubtext}>
                    Please select the preferred class timings for your kid’s
                    next class
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                      <Box className={classes.mentorAndTopicContainer}>
                        <Box className={classes.mentorTopicHeading}>
                          <img
                            src={mentor}
                            alt="mentor"
                            style={{ marginRight: "5px" }}
                          />
                          Showing availability of Teacher
                        </Box>
                        <Box
                          variant="body1"
                          className={classes.mentorTopicName}
                        >
                          {`${
                            classDetails?.classDetails.FNAME
                              ? classDetails.classDetails.FNAME
                              : ""
                          } ${
                            classDetails?.classDetails.LNAME
                              ? classDetails.classDetails.LNAME
                              : ""
                          }`}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Box className={classes.mentorAndTopicContainer}>
                        <Box className={classes.mentorTopicHeading}>
                          <img
                            src={time}
                            alt="mentor"
                            style={{ margin: "-4px 5px 0px 0px" }}
                          />
                          Course
                        </Box>
                        <Box className={classes.mentorTopicName}>
                          {classDetails?.classDetails.courseName}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {!loader ? (
                      <Box className={classes.selectTimeContainer}>
                        <Form.Group className="col-lg-6">
                          <Form.Label
                            style={{ fontSize: "14px", lineHeight: "19px" }}
                          >
                            Select time zone{" "}
                          </Form.Label>
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                          <div className={classes.languageSelector}>
                            <TextField
                              SelectProps={{
                                MenuProps: {
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                  PaperProps: {
                                    style: {
                                      maxHeight: "360px",
                                    },
                                  },
                                },
                              }}
                              select
                              variant="outlined"
                              onChange={onTimeZoneChangeHandler}
                              value={timeZone}
                              fullWidth
                              size="small"
                              className={classes.selectLanguageField}
                            >
                              {timeZoneList.map((data, index) => (
                                <MenuItem
                                  value={data.value}
                                  key={index}
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  }}
                                >
                                  <img
                                    src={data.img}
                                    alt=""
                                    style={{
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  {data.timeZones}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </Form.Group>

                        {Object.keys(allSlots).length > 0 && isFreeSlotAvailable && !lastClassPassed &&
                        classDetails?.classDetails.typeOfCourse == "1-on-1" ? (
                          <>
                            <Form.Group className="col-lg-12 mt-2">
                              <Form.Label>Select date </Form.Label>
                              <span className="text-danger font-weight-bold">
                                *
                              </span>
                              <Box className={classes.dayBox}>
                                {nextSevenDays.length &&
                                  nextSevenDays.map((date, index) => {
                                    const numberOfSlots = getSlotsCountByDay(
                                      date,
                                      allSlots
                                    );
                                    const dates = new Date(date);
                                    const weekday = dates.toLocaleString(
                                      "default",
                                      {
                                        weekday: "short",
                                      }
                                    );
                                    const month = dates.toLocaleString(
                                      "default",
                                      {
                                        month: "short",
                                      }
                                    );
                                    return (
                                      <Box>
                                        <button
                                          key={index}
                                          type="button"
                                          className={
                                            selectedButton === index &&
                                            numberOfSlots.totalSlots > 0
                                              ? classes.activeBtn
                                              : numberOfSlots.totalSlots > 0
                                              ? classes.inactiveBtn
                                              : classes.disabledBtn
                                          }
                                          onClick={() => {
                                            autoRescheduleGTM(
                                              "reschedule_date_selected"
                                            );
                                            selectDate(index, date);
                                          }}
                                          disabled={
                                            numberOfSlots.totalSlots === 0
                                          }
                                        >
                                          <Box className={classes.day}>
                                            {weekday}
                                          </Box>
                                          <Box className={classes.date}>
                                            {new Date(date).getDate()}
                                          </Box>
                                          <Box className={classes.date}>
                                            {month}
                                          </Box>
                                        </button>
                                        <Box
                                          className={
                                            numberOfSlots.freeSlots > 0
                                              ? classes.slotsNumber
                                              : classes.noSlots
                                          }
                                        >
                                          {`${numberOfSlots.freeSlots} slots`}
                                        </Box>
                                      </Box>
                                    );
                                  })}
                              </Box>
                            </Form.Group>
                            <Form.Group className="col-lg-12 mt-2">
                              {displayedSlots.length > 0 && (
                                <>
                                  <Form.Label>Select timings </Form.Label>
                                  <span className="text-danger font-weight-bold">
                                    *
                                  </span>
                                </>
                              )}
                              <Box className={classes.displaySlots}>
                                {displayedSlots.length > 0 &&
                                  displayedSlots
                                    .sort((a, b) => {
                                      if (a.slotDate < b.slotDate) {
                                        return -1;
                                      }
                                      if (a.slotDate > b.slotDate) {
                                        return 1;
                                      }
                                      return 0;
                                    })
                                    .map((data, key) => (
                                      <div className="mt-3">
                                        <button
                                          type="button"
                                          className={
                                            selectedSlot.slotButtonState === key
                                              ? classes.activeTimeBtn
                                              : !data.free
                                              ? classes.disabledTimeBtn
                                              : classes.inActiveTimeBtn
                                          }
                                          disabled={!data.free}
                                          onClick={() => {
                                            autoRescheduleGTM(
                                              "reschedule_time_selected"
                                            );
                                            handleSelectSlot(data.slotId, key);
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              paddingTop: "7px",
                                            }}
                                          >
                                            <p className={classes.time}>
                                              {formateDate(
                                                new Date(data?.slotDateTz)
                                              )}
                                            </p>
                                          </div>
                                        </button>
                                      </div>
                                    ))}
                              </Box>
                            </Form.Group>
                          </>
                        ) : classDetails?.classDetails.typeOfCourse !==
                          "1-on-1" ? (
                          <ErrorModal error={false} />
                        ) : lastClassPassed ?(
                          <ErrorModal error={false} lastClassPassed={true}/>
                        ) : !isFreeSlotAvailable ?(
                          <ErrorModal error={false} freeSlotError={true} />
                        ) :(
                          <ErrorModal error={true} />
                        )}
                      </Box>
                    ) : (
                      <Grid item xs={12} sm={10}>
                        <Spinner animation="border" variant="grey" />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    {classDetails?.classDetails.typeOfCourse == "1-on-1" &&
                      Object.keys(allSlots).length > 0 && (
                        <Box className={classes.sbmitBtnBox}>
                          <div className={classes.submitBtnwrapper}>
                            <Button
                              onClick={submitHandler}
                              className={
                                Object.keys(allSlots).length === 0 ||
                                selectedSlot.slotId === ""
                                  ? classes.disableSubmitBtn
                                  : classes.submitBtn
                              }
                              disabled={
                                Object.keys(allSlots).length === 0 ||
                                selectedSlot.slotId === "" ||
                                submitLoader
                              }
                            >
                              {submitLoader ? (
                                <Spinner animation="border" variant="white" />
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </div>
                        </Box>
                      )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </div>
  );
};

export default Reschedule;
