import Axios from "axios";
import sessionExpired from "../Core/sessionExpired";
import { decrypt } from "../utils/other";
// import { BASE_URL } from "./../Core/constants";

const BASE_URL = process.env.REACT_APP_STUDENTLOGIN_BASE_URL;
const EPICENTER_BASE_URL = process.env.REACT_APP_EPICENTERAPI_BASE_URL;
const api = function (baseUrl) {
  this.apiBase = baseUrl;
  const accesToken = decrypt(localStorage.getItem("user"));
  this.accesToken = accesToken ? accesToken.ACCESS_TOKEN : "";
  this.refreshToken = accesToken ? accesToken.REFRESH_TOKEN : "";
  this.axios = Axios.create({
    baseURL: this.apiBase,
    timeout: 100000000,
    //#region [comment]
    // onUploadProgress ( p ) {
    //     const progress = p.loaded / p.total;

    //     // check if we already displayed a toast
    //     if ( toastId === null ) {
    //         toastId = toast( 'Upload in Progress', {
    //             progress: progress
    //         } );
    //     } else {
    //         toast.update( toastId, {
    //             progress: progress
    //         } );
    //     }
    // },
    // onDownloadProgress ( p ) {
    //     const progress = p.loaded / p.total;

    //     // check if we already displayed a toast
    //     if ( toastId === null ) {
    //         toastId = toast( 'Download in Progress', {
    //             progress: progress
    //         } );
    //     } else {
    //         toast.update( toastId, {
    //             progress: progress
    //         } );
    //     }
    // }
    //#endregion
  });
  this.axios.defaults.headers.post["Content-Type"] = "application/json";
  this.axios.interceptors.request.use(
    (req) => {
      const tokenObj = decrypt(localStorage.getItem("user"));
      req["headers"]["Authorization"] = `Bearer ${tokenObj.ACCESS_TOKEN}`;
      req["headers"]["refresh-token"] = tokenObj.REFRESH_TOKEN;
      return req;
    },
    (e) => {
      return Promise.reject(e);
    }
  );
  this.axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (e) => {
      if (
        e.response &&
        e.response.status === 401 &&
        e.response.data.msg === "Invalid Authorization"
      ) {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) &&
          window.ReactNativeWebView
        ) {
          window.location.replace("/");
        } else {
          sessionExpired();
        }
      }
      return Promise.reject(e);
    }
  );
};

api.prototype.getApiBase = function () {
  return this.apiBase;
};

api.prototype.get = function (url, params = {}, data = {}) {
  return this.axios({
    method: "get",
    url,
    params,
    data,
  });
};

api.prototype.post = function (url, data = {}, params = {}) {
  return this.axios({
    method: "post",
    url,
    data,
    params,
  });
};

api.prototype.put = function (url, data, params = {}) {
  return this.axios({
    method: "put",
    url,
    data,
    params,
  });
};

api.prototype.delete = function (url, data) {
  return this.axios({
    method: "delete",
    url,
    data,
  });
};

const API = new api(BASE_URL);
const API_EPICENTER = new api(EPICENTER_BASE_URL);

export { API, API_EPICENTER, api };
